import React from "react";
import '../style.scss';
export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros Valores</h2>
          <div className="section-title">
          <h3>Filosofía</h3>
          <h4>Investigación constante, mejora continua:</h4>
          <p>
          Somos una empresa que no se conforma con el status quo. La investigación y la innovación forman parte integral de nuestro ADN, impulsándonos a buscar constantemente nuevas formas de optimizar nuestros procesos, mejorar la calidad de nuestros productos y brindar un servicio aún más excepcional a nuestros clientes.
          </p>

            <h3>Misión</h3>
            <h4>Alimentando a Chihuahua con pasión y responsabilidad:</h4>
          <p>
          En Hermanos Jáquez Ochoa, lo tenemos claro: brindar a los chihuahuenses productos frescos, de la más alta calidad y a precios justos, siempre con un servicio excepcional y un profundo compromiso de familia a familias.
          </p>
          </div>

          <h3>Visión</h3>
          <h4>Un futuro brillante, alimentado por la tradición:</h4>
          <p>
          Miramos hacia el futuro con optimismo y determinación, convencidos de que Hermanos Jáquez Ochoa tiene el potencial de convertirse en un referente nacional e internacional en la distribución de productos frescos. Por ello en 2017 invertimos en la renovación de nuestras instalaciones para contar con energía renovable y contribuir al cuidado del medio ambiente.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
